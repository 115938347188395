import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eca-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {

  @Input()
  public id: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public inputContent: string;

  @Input()
  public isEditable: boolean;

  @Input()
  public isResizable: boolean;

  @Input()
  public rows: number;

  @Output()
  public contentChanged = new EventEmitter<string>();

  constructor() {
    this.id = this.getRandomString();
    this.isEditable = true;
    this.isResizable = true;
    this.rows = 10;
  }

  public valuechange(content: string): void {
    this.contentChanged.emit(content)
  }

  private getRandomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

}
