import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eca-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent {
  
  @Input()
  public id: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public inputContent: string;

  @Input()
  public isEditable: boolean;

  @Output()
  public contentChanged = new EventEmitter<string>();

  public inputType = 'password';

  constructor() {
    this.id = this.getRandomString();
    this.isEditable = true;
  }

  public valuechange(content: string): void {
    this.contentChanged.emit(content)
  }

  public showHidePassword(): void {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      return;
    }
    this.inputType = 'password';
  }

  private getRandomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

}
