export class DropDownOption {
  public id: string = null;
  public name: string;
  public disabled: boolean = false;
  public icon: string = null;

  constructor(name: string, id?: string, disabled?: boolean, icon?: string) {
    this.id = id ? id : name;
    this.name = name ? name : null;
    this.disabled = disabled ? disabled : false;
    this.icon = icon ? icon : null;
  }
}