import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eca-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent {
  @Input() public mdIcon: string;
  @Input() public svgIcon: string;
  @Input() public text: string;
  @Input() public disabled = false;
  @Output() private click = new EventEmitter<ToolbarButtonComponent>();

  constructor() {
  }

  public onClick(event: Event) {
    if (!this.disabled) {
      this.click.emit(this);
    }
    event.stopPropagation();
  }

}
