import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'eca-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {

  @Input()
  public id: string = null;
  @Input()
  public group: string;
  @Input()
  public value: string;
  @Input()
  public label: string = null;
  @Input()
  public labelAtFront: boolean = false;
  @Input()
  public selected: boolean = false;
  @Input()
  public disabled: boolean = false;
  @Output()
  public valueChanged = new EventEmitter<any>();

  constructor(utilityService: UtilityService) {
    this.id = this.id ? this.id : utilityService.generateGuid();
  }

  /**
   * triggerd once the element selection state changed
   *
   * @return an object consisting of:
   *    id: the id of the clicked element
   *    selected: the new selection state
   *    group: the group of the element
   *    value: the value of the element
   *    event: the complete event
   */
  public onChange(event: any): void {
    this.valueChanged.emit({ id: event.target.id, selected: event.target.checked, group: this.group, value: this.value, event: event });
  }

}
