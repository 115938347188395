import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnChanges {
  public static TAG = '[LS]      ';

  @Input() height: string;
  @Input() width: string;
  @Input() keyframeClass: string; // other possibilities -> loading-spinner.component.scss

  public realHeight = '60px';
  public realWidth = '60px';
  public realKeyframeClass = 'color-default';

  constructor() {
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  private initialize(): void {

    if (this.height) {
      this.realHeight = this.height;
    }

    if (this.width) {
      this.realWidth = this.width;
    }

    if (this.keyframeClass) {
      this.realKeyframeClass = this.keyframeClass;
    }
  }


}