import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  animations: [
    trigger('enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('500ms', style({opacity: 1})),
        ]),
      ],
    )],
  selector: 'eca-upload-list-item',
  templateUrl: './upload-list-item.component.html',
  styleUrls: ['./upload-list-item.component.scss']
})
export class UploadListItemComponent {
  
    @Input()
    public name: string;
    
    @Input()
    public nameLabel: string;
    
    @Input()
    public progress: number = 0;
    
    @Input()
    public isError: boolean = false;
    
    @Output()
    public nameChanged = new EventEmitter<string>();
    
    @Output()
    public cancel = new EventEmitter<boolean>();
    
    constructor(){

    }

    public handleNameChange(event: Event): void {
      this.nameChanged.emit(String(event));
    }
    
    public handleDownloadCancelled(event: Event): void {
      this.cancel.emit(true);
    }

  }
