import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityService } from '../../services/utility/utility.service';
import { RadioButtonOption } from '../../models/radioButtonOption.class';


@Component({
  selector: 'eca-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements OnInit {

  @Input()
  public name: string;
  @Input()
  public default: string;
  @Input()
  public options: Array<RadioButtonOption>;
  @Input()
  public direction: string = 'vertical';
  @Output()
  public valueChanged = new EventEmitter<any>();

  value: string;

  ngOnInit(): void {
    this.value = this.default ? this.default : null;
    this.name = this.name ? this.name : this.utilityService.generateGuid();
  }

  constructor(private utilityService: UtilityService) {

  }

  /**
   *
   * @returns an object consisting of the group name and its value { group , value }
   */
  public onValueChanged(event: any) {
    this.value = event.value;
    this.valueChanged.emit({ group: this.name, value: this.value });
  }
}
