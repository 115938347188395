export class EcaToolbarItem {
    public static TYPE_ACTION: number = 0;
    public static TYPE_CALLBACK: number = 1;
    public static TYPE_COLOR: number = 2;
    public static TYPE_COLOR_MENU: number = 3;
  
    public actionName: string;
    public icon: string;
    public iconType: EcaIconType;
    public text: string;
    public subItems: EcaToolbarItem[];
    public callback: () => void;
    public type: number;
    public activeCallback: () => boolean;
  
    constructor(){}

    public static withAction(actionName: string, iconType: EcaIconType, icon: string, text?: string, subItems?: EcaToolbarItem[]): EcaToolbarItem {

      var tempSubItems = subItems ? subItems : null;

      return {
        actionName: actionName,
        icon: icon,
        iconType: iconType,
        text: text,
        callback: undefined,
        subItems: tempSubItems,
        type: this.TYPE_ACTION,
        activeCallback: undefined
      };
    }
  
    public static withCallback(caller: any, callback: (parameters?: any) => void, iconType: EcaIconType, icon: string,
      text?: string, subItems?: EcaToolbarItem[], type?: number, activeCallback?: () => boolean): EcaToolbarItem {
      
      var tempSubItems = subItems ? subItems : null;
      var tempCaller = caller && callback ? callback.bind(caller) : callback;
      
      return {
        actionName: undefined,
        icon: icon,
        iconType: iconType,
        text: text,
        callback: tempCaller,
        subItems: tempSubItems,
        type: type ? type : this.TYPE_CALLBACK,
        activeCallback: activeCallback
      };
    }
  
    public static colorItem(caller: any, callback: (parameters?: any) => void, colorValue?: string, activeCallback?: () => boolean): EcaToolbarItem {

      var tempCaller = caller && callback ? callback.bind(caller) : callback;

      return {
        actionName: undefined,
        icon: null,
        iconType: null,
        text: colorValue,
        callback: tempCaller,
        subItems: null,
        type: this.TYPE_COLOR,
        activeCallback: activeCallback
      };
    }
}

export enum EcaIconType {
    NoIcon,
    MaterialDesignIcon,
    SvgIcon
}