import { Component, OnInit, Input, ViewChildren } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { EcaToolbarItem, EcaIconType } from '../../models/toolbar.class';



@Component({
  selector: 'eca-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input()
  public icon: EcaToolbarItem;

  @Input()
  public title: string;

  @Input()
  public items: EcaToolbarItem[] = [];

  @Input()
  public titlePrefix: string = null;

  @Input()
  public subtitle: string = null;

  @Input()
  public editableSubtitle: boolean = true;

  @Input()
  public alternateStyle: boolean = false;

  @Output()
  private action = new EventEmitter<EcaToolbarItem>();

  @Output()
  private subtitleChanged = new EventEmitter<string>();

  @ViewChildren('subtitleInput') subtitleInput;

  public editModeActive;

  public constructor() {
    this.editModeActive = false;
  }

  public getMdIcon(item: EcaToolbarItem): string {
    return item.iconType === EcaIconType.MaterialDesignIcon ? item.icon : undefined;
  }

  public getSvgIcon(item: EcaToolbarItem): string {
    return item.iconType === EcaIconType.SvgIcon ? item.icon : undefined;
  }

  public onClick(item: EcaToolbarItem) {
    if (item.callback) {
      item.callback();
    }
    if (item.actionName) {
      this.action.emit(item);
    }
  }

  public isItemActive(item: EcaToolbarItem) {
    if(item && item.activeCallback) {
      return item.activeCallback();
    }
    return false;
  }

  public onClickToggleEditMode() {
    this.editModeActive = !this.editModeActive;
    const inputId = this.subtitleInput.first.id;
    const input: any = document.getElementById(inputId);
    if(input) {
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  }

  public onSubtitleChange(subtitle: string) {
    this.subtitle = subtitle;
    this.subtitleChanged.emit(subtitle);
  }

  public onFocusOutSubtitle(event: any) {
    this.editModeActive = false;
  }
  
}

