import { UtilityService } from "../services/utility/utility.service";


export class RadioButtonOption {
    public id: string = null;
    public group: string;
    public value: string;
    public label: string = null;
    public labelAtFront: boolean = false;
    public disabled: boolean = false;
  
    constructor(value: string, label: string, labelAtFront?: boolean, disabled?: boolean, id?: string) {

      this.id = id ? id : this.generateGuid();
      this.value = value ? value : null;
      this.label = label ? label : null;
      this.labelAtFront = labelAtFront ? labelAtFront : false;
      this.disabled = disabled ? disabled : false;
    }

    private generateGuid(): string{
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:no-bitwise
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    }
  }