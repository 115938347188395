import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eca-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() public mdIcon: string;
  @Input() public svgIcon: string;
  @Input() public text: string;
  @Input() public disabled = false;
  @Output() private click = new EventEmitter<ButtonComponent>();

  constructor() {
  }

  public onClick(event: Event) {
    if (!this.disabled) {
      this.click.emit(this);
    }
    event.stopPropagation();
  }
}
