import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'eca-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnChanges{

  @Input() public percentage: number;
  @Input() public ignoreSuccessColorChange: boolean = false;
  @Input() public isError: boolean = false;
  
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isError && changes.isError.currentValue) {
      this.percentage = 100;
    }
  }

  constructor(){}

}
