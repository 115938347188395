import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'eca-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss']
})
export class UploadAreaComponent implements OnInit {

  @Output()
  public filesSelected = new EventEmitter();
  
  @Input()
  public disabled: boolean = false;
  
  @ViewChild('uploadArea')
  public uploadArea: ElementRef;
  @ViewChild('uploadOverlay')
  public uploadOverlay: ElementRef;
  public isActive: boolean = false;
  
  constructor() {}
  
  public ngOnInit(): void {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName: string) => {
      this.uploadOverlay.nativeElement.addEventListener(eventName, this.preventDefaults.bind(this), false);
      document.body.addEventListener(eventName, this.preventDefaults.bind(this), false);
    });
    
    this.uploadOverlay.nativeElement.addEventListener('dragover', this.dragoverEventHandler.bind(this));
    this.uploadOverlay.nativeElement.addEventListener('dragleave', this.dragleaveEventHandler.bind(this));
    this.uploadOverlay.nativeElement.addEventListener('drop', this.dropEventHandler.bind(this));
  }
  
  public preventDefaults(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }
  
  public dragoverEventHandler(): void {
    this.isActive = true;
  }
  
  public dragleaveEventHandler(): void {
    this.isActive = false;
  }
  
  public dropEventHandler(event: Event): void {
    event.stopPropagation();
    this.isActive = false;
    console.log((event as any).dataTransfer.files);
    this.filesSelected.emit((event as any).dataTransfer.files);
  }
  
  public filesSelectedEventHandler(event: Event): void {
    this.isActive = false;
    this.filesSelected.emit(event);
  }
}
