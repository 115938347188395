import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UtilityService } from '../../services/utility/utility.service';
import { DropDownOption } from '../../models/dropDown.class';

@Component({
  selector: 'eca-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {
  @Input()
  public id: string = null;
  @Input()
  public default: DropDownOption;
  @Input()
  public label: string = null;
  @Input()
  public disabled: boolean = false;
  @Input()
  public options: Array<DropDownOption>;
  @Output()
  public valueChanged = new EventEmitter<any>();

  open: boolean;
  value: DropDownOption;

  constructor(utilityService: UtilityService) {
    this.id = this.id ? this.id : utilityService.generateGuid();
  }

  ngOnInit(): void {
    this.value = this.default;
    this.open = false;
  }

  public onClickField(event: Event) {
    if(this.disabled === false) {
      const drawer: HTMLElement = document.getElementById(this.id + '-drawer');
      if(drawer != null) {
        if(drawer && this.open) {
          // close drawer
          drawer.setAttribute('style', '');
          this.open = false;
        } else {
          // open drawer
          drawer.setAttribute('style', 'display: block;');
          this.open = true;
        }
      }
    }
  }

  public onClickOption(event: Event, option: DropDownOption) {
    if(option.disabled === false) {
      if(this.value !== option) {
        this.value = option;
        this.valueChanged.emit({ id: this.id, value: this.value, event: Event });
      }
      this.onClickField(null);
    }
  }

  /**
   * triggerd once the element selection state changed
   *
   * @return an object consisting of:
   *    id: the id of the drop-down element
   *    value: the selected value
   *    event: the change event
   */
  public onChange(event: any): void {
    this.valueChanged.emit({ id: this.id, value: this.value, event: event });
  }
}
