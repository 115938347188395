import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EcaToolbarItem, EcaIconType } from '../../models/toolbar.class';

@Component({
  selector: 'eca-toolbar-menu-button',
  templateUrl: './toolbar-menu-button.component.html',
  styleUrls: ['./toolbar-menu-button.component.scss']
})
export class ToolbarMenuButtonComponent {

  @Input() public item: EcaToolbarItem;
  @Input() public disabled: boolean = false;
  @Input() public alternateStyle: boolean = false;
  @Output() private click = new EventEmitter<EcaToolbarItem>();

  public opened: boolean = false;

  constructor() {
  }

  public getMdIcon(item: EcaToolbarItem): string {
    return item.iconType === EcaIconType.MaterialDesignIcon ? item.icon : undefined;
  }

  public getSvgIcon(item: EcaToolbarItem): string {
    return item.iconType === EcaIconType.SvgIcon ? item.icon : undefined;
  }

  public isColorItem(item: EcaToolbarItem): boolean {
    if(item && item.type) {
      return EcaToolbarItem.TYPE_COLOR === item.type;
    }
    return false;
  }

  public isColorMenu(item: EcaToolbarItem): boolean {
    if(item && item.type) {
      return EcaToolbarItem.TYPE_COLOR_MENU === item.type;
    }
    return false;
  }

  public isItemActive(item: EcaToolbarItem) {
    if(item && item.activeCallback) {
      return item.activeCallback();
    }
    return false;
  }

  public onFocusOut(event: any) {
    // insert a little delay before closing to get click event inside the closed box (if it was inside the box)
    setTimeout(() => {
      this.opened = false;
    }, 200);
  }

  public onClick(event: EcaToolbarItem) {
    if (!this.disabled) {
      if(event && event.subItems && event.subItems.length > 0) {
        // clicked on parent -> open | collapse menu
        this.opened = !this.opened;
      } else {
        // clicked normal menu item -> emit action
        this.click.emit(event);
      }
    }
  }

}
