import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'eca-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements OnChanges {

  @Input()
  public observable: Observable<any>;

  private subscription: Subscription;


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.observable && changes.observable.previousValue !== changes.observable.currentValue) {
      const newObservable: Observable<any> = changes.observable.currentValue;

      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
      }
      if (newObservable) {
        this.subscription = newObservable.subscribe({
          next: (val) => {},
          complete: () => {
            this.subscription = undefined;
          },
          error: err => {
            this.subscription = undefined;
          }
        });
      }
    }
  }

  public isLoading(): boolean {
    return this.subscription && !this.subscription.closed;
  }
}
