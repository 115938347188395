import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'eca-modal-button',
  templateUrl: './modal-button.component.html',
  styleUrls: ['./modal-button.component.scss']
})
export class ModalButtonComponent {

  @Input()
  public id: string;

  @Input()
  public materialIcon: string;

  @Input()
  public buttonText: string;

  @Input()
  public buttonType: string;

  @Input()
  public isEnabled: boolean;

  @Input()
  public iconColor: string;

  @Output()
  public click = new EventEmitter<any>();

  public iconName: string;

  constructor() {
    this.id = this.getRandomString();
    this.isEnabled = true;
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges && simpleChanges['materialIcon']) {
      this.iconName = simpleChanges['materialIcon'].currentValue;
    }

    if (!this.iconName) {
      this.iconName = this.buttonType;
    }
  }

  private getRandomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  public handleButtonClick(event: Event) {
    this.click.emit(event);
    event.stopPropagation();
  }

}
