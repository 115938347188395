import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
  selector: 'eca-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input()
  public id: string = null;
  @Input()
  public label: string = null;
  @Input()
  public labelAtFront: boolean = false;
  @Input()
  public selected: boolean = false;
  @Input()
  public disabled: boolean = false;
  @Output()
  public valueChanged = new EventEmitter<any>();

  constructor(utilityService: UtilityService) {
    this.id = this.id ? this.id : utilityService.generateGuid();
  }

  /**
   * triggerd once the element selection state changed
   *
   * @return an object consisting of:
   *    id: the id of the clicked element
   *    selected: the new selection state
   *    event: the complete event
   */
  public onChange(event: any): void {
    this.valueChanged.emit({ id: event.target.id, selected: event.target.checked, event: event });
  }

}
