import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EcaComponentsComponent } from './eca-components.component';
import * as servicesBundle from './services/services.bundle';
import { ButtonComponent } from './components/button/button.component';
import { IconComponent } from './components/icon/icon.component';
import { ToolbarComponent} from './components/toolbar/toolbar.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { ToolbarButtonComponent } from './components/toolbar-button/toolbar-button.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component'; 
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { LoadingSpinnerComponent } from './components/utility/loading-spinner/loading-spinner.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { ButtonFileInputComponent } from './components/button-file-input/button-file-input.component';
import { UploadListItemComponent } from './components/upload-list-item/upload-list-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UploadAreaComponent } from './components/upload-area/upload-area.component';
import { ToolbarMenuButtonComponent } from './components/toolbar-menu-button/toolbar-menu-button.component'; 


@NgModule({
  declarations: [EcaComponentsComponent, 
                 ButtonComponent, 
                 ButtonFileInputComponent,
                 CheckboxComponent,
                 DropDownComponent,
                 IconComponent,
                 LoadingOverlayComponent,
                 LoadingSpinnerComponent,
                 ModalButtonComponent,
                 PasswordInputComponent,
                 ProgressbarComponent,
                 RadioButtonComponent,
                 RadioGroupComponent,
                 TextAreaComponent,
                 TextInputComponent,
                 ToolbarButtonComponent,
                 ToolbarComponent,
                 ToolbarMenuButtonComponent,
                 UploadAreaComponent,
                 UploadListItemComponent,
                ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule
  ],
  providers:[
    servicesBundle.UtilityService
  ],
  exports: [EcaComponentsComponent,
            ButtonComponent, 
            ButtonFileInputComponent,
            CheckboxComponent,
            DropDownComponent,
            IconComponent,
            LoadingOverlayComponent,
            ModalButtonComponent,
            PasswordInputComponent,
            ProgressbarComponent,
            RadioButtonComponent,
            RadioGroupComponent,
            TextAreaComponent,
            TextInputComponent,
            ToolbarButtonComponent,
            ToolbarComponent,
            ToolbarMenuButtonComponent,
            UploadAreaComponent,
            UploadListItemComponent
            ]
})

export class EcaComponentsModule { }
