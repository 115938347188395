import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'eca-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  public static ICON_BASE_URL = '/assets/img/icons/';

  @Input()
  public mdIcon: string;

  @Input()
  public svgIcon: string;

  @Input()
  public smallSized: boolean = false;

  @ViewChild('svgContainer')
  public svgContainer: ElementRef;

  private iconSub: Subscription;

  constructor(private http: HttpClient) {
  }

  public ngAfterViewInit(): void {
    this.loadSvg(this.svgIcon);
  }

  public ngOnDestroy(): void {
    try { if (this.iconSub) { this.iconSub.unsubscribe(); }} catch (error) {}
  }

  private loadSvg(url: string) {
    if (!url || this.mdIcon) { return; }

    if (!url.startsWith('/') && !url.startsWith('https://') && !url.startsWith('http://')) {
      url = IconComponent.ICON_BASE_URL + url;
    }

    if (!url.endsWith('.svg')) {
      url += '.svg';
    }

    this.svgContainer.nativeElement.innerHTML = '';
    this.iconSub = this.http.get(url, { responseType: 'text' as 'text'})
      .subscribe(content => this.svgContainer.nativeElement.innerHTML = content);
  }
}
