import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'eca-button-file-input',
  templateUrl: './button-file-input.component.html',
  styleUrls: ['./button-file-input.component.scss']
})
export class ButtonFileInputComponent implements OnChanges {

  @Input()
  public id: string;

  
  @Input()
  public materialIcon: string;

  
  @Input()
  public buttonText: string;

  
  @Input()
  public buttonType: string;

  
  @Input()
  public isEnabled: boolean;

  
  @Input()
  public iconColor: string;

  
  @Input()
  public enableMultipleFiles: boolean = true;
  
  @Output()
  public filesSelected = new EventEmitter<any>();

  public iconName: string;

  
  constructor() {
    this.id = this.getRandomString();
    this.isEnabled = true;
  }

  
  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges && simpleChanges['materialIcon']) {
      this.iconName = simpleChanges['materialIcon'].currentValue;
    }

    
    if (!this.iconName) {
      this.iconName = this.buttonType;
    }
  }

  
  private getRandomString() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
  
  public handleFileChange(event: Event) {
    this.filesSelected.emit((event.target as any).files);
    event.stopPropagation();
  }


}
